import Link from 'next/link';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import classNames from 'classnames';

import Image from 'src/components/Image';
import ImageC from 'src/components/Image';

import classes from './index.module.less';

const Footer = () => {
	return (
		<footer className={classes.footer}>
			<div className={classes['footer-container']}>
				<div className="container">
					<Row
						gutter={[20, 35]}
						className={classNames(classes.content)}
						// style={{ padding: deviceSize === 'desktop' ? '0 35px' : '0' }}
					>
						<Col xs={24} lg={12}>
							<Image alt="CREOPLAY" className={classes.logo} src="/icons/creoplay-with-text.svg" />

							<div className={classes.copyright}>
								<p>
									© 2022 CreoPlay. All rights reserved. All trademarks are property of their
									respective owners in the US and other countries.
								</p>
							</div>
							<div className={classes.socials}>
								<Link
									href={'https://discord.gg/creoengine'}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ImageC alt="icon discord" src="/icons/discord.svg" height={36} width={36} />
								</Link>
								<Link
									href={'https://youtube.com/@creoengineofficial'}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ImageC alt="icon youtube" src="/icons/youtube.svg" height={36} width={36} />
								</Link>
								<Link
									href={'https://twitter.com/CreoPlay_app'}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ImageC alt="icon twitter" src="/icons/twitter.svg" height={36} width={36} />
								</Link>
								<Link
									href={'https://www.instagram.com/creoplay.app/'}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ImageC alt="icon instagram" src="/icons/instagram.svg" height={36} width={36} />
								</Link>
								<Link
									href={'https://www.facebook.com/CreoPlayOfficial'}
									target="_blank"
									rel="noopener noreferrer"
								>
									<ImageC
										alt="icon facebook"
										src="/icons/facebook-outline.svg"
										height={36}
										width={36}
									/>
								</Link>
							</div>
						</Col>
						<Col xs={24} md={12} lg={4} className={classes['menu-container']}>
							<h3 className={classes.title}>HELP</h3>
							<div className={classes['menu-list']}>
								<Link href="#">FAQs</Link>
								<Link href="#">Contact Support</Link>
							</div>
						</Col>
						<Col xs={24} md={12} lg={4} className={classes['menu-container']}>
							<h3 className={classes.title}>ABOUT</h3>
							<div className={classes['menu-list']}>
								<Link href="https://creoengine.gitbook.io/whitepaper/">Whitepaper</Link>
								<Link href="#">Terms and Conditions</Link>
								<Link href="#">Privacy Policy</Link>
							</div>
						</Col>
						<Col xs={24} md={12} lg={4} className={classes['menu-container']}>
							<h3 className={classes.title}>$CREO TOKEN</h3>
							<div className={classes['menu-list']}>
								<Link href="#">Get $CREO</Link>
								<Link href="#">Smart Contract</Link>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
